import styled from "styled-components";

export const TabsCompStyle: any = styled.ul`
  border: 1px solid rgba(0, 112, 204, 0.5);
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  margin: ${(p: any) => (p.margin ? p.margin : "")};

  .bold {
    font-weight: 900;
  }

  ${(props: any) =>
    props.mwebTab &&
    `
      border:unset;
      gap:8px;
   `}

  ${(props: any) =>
    props.mwebTab2 &&
    `
      border:unset;
      gap:8px;
   `}

   ${(props: any) =>
    props.deliverySpeed &&
    `
      border:unset;
      gap:8px;
   `}
   ${(props: any) =>
    props.deliverySpeedMweb &&
    `
      border:unset;
      gap:8px;
   `}

   ${(props: any) =>
    props.disableState &&
    `
      border:unset;
      pointer-events:none;
   `}
`;

export const TabsCompItem: any = styled.li`
  width: 137px;
  flex-shrink: 0;
  height: 40px;
  border-right: 1px solid rgba(0, 112, 204, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  ${(props: any) =>
    props.deliverySpeed &&
    `
         width:144px;
         background: #FFFFFF;
         border:unset;
         box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
         border-radius: 4px;
         flex-direction:column;
         padding:8px 12px;
         height:58px;
         margin:1px;
         color:#757575;
      `}

  ${(props: any) =>
    props.deliverySpeedMweb &&
    `
         width:100%;
         flex:1;
         background: #FFFFFF;
         border:unset;
         box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
         border-radius: 4px;
         flex-direction:column;
         padding:8px;
         height:58px;
         margin:1px;
         color:#757575;
      `}

      ${(props: any) =>
    props.mwebTab &&
    `
         background: #FFFFFF;
         box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
         border-radius: 4px;
         margin:1px;
         border-right:unset;
         width:unset;
         flex:1;
      `}

      ${(props: any) =>
    props.mwebTab2 &&
    `
         background: #FFFFFF;
         border: 1px solid #E2E2E2;
         border-radius: 6px;
         flex :1;
         width:unset;
`}
:last-child {
    border-right: none;
    ${(props: any) =>
      props.mwebTab2 &&
      `
            border: 1px solid #E2E2E2;
`}
  }
  .active {
    background: #ecf5fe;
    border-bottom: 3px solid #008cff;
    color: #008cff;
    ${(props: any) =>
      props.deliverySpeed &&
      `
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), linear-gradient(93.66deg, #53B2FE 0%, #065AF3 100%);
            border-radius: 4px;
            color: #FFFFFF;
            border-bottom:unset;
      `}
    ${(props: any) =>
      props.deliverySpeedMweb &&
      `
            background: #F8FBFF;
            border: 1px solid #008CFF;
            border-radius: 4px;  
            color: #008CFF;
      `}
      ${(props: any) =>
      props.mwebTab &&
      `
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)), linear-gradient(93.66deg, #53B2FE 0%, #065AF3 100%);
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            color: #FFFFFF;
            border-bottom:unset;
      `}
       ${(props: any) =>
      props.mwebTab2 &&
      `
            background: #F8FBFF;
            border: 1px solid #008CFF !important;
`}
  }
`;

export const FilterTabStyle: any = styled(TabsCompStyle)`
  gap: 8px;
  border: unset;
  margin: 15px 2px 0;
  li {
    flex: 1;
    border-right: unset;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin: 2px 1px;
    color: #4a4a4a;
  }
  li.active {
    background: #eaf5ff;
    border: 1px solid #008cff;
    border-radius: 4px;
    font-weight: 600;
    color: #008cff;
  }
`;

export const SwitchTabStyle: any = styled(TabsCompStyle)`
  border: unset;
  li {
    flex: 1;
    border: unset;
  }

  li.active {
    color: #008cff;
    background: #fff;
    border-bottom: 4px solid #008cff;
  }
`;
