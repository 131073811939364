import React from "react";

import * as S from "./LandingSideBar.style";
import { pushEvent } from "@tm/utility/analytics";
import { Image } from "../Image";

const LandingSideBar = ({
  sidebarArr,
  blackCross,
  faq,
  aboutUs,
  show,
  handleClose,
  faqCTAEvent,
}: any) => {
  const onClickFaq = () => {
    const el = document.getElementById("landing_faq_section_mobile");
    el?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    if (faqCTAEvent) pushEvent(faqCTAEvent);
    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <S.LandingSideBar show={show}>
        <div onClick={handleClose}>
          <S.BlackCross>
            <Image {...blackCross} width="12" height="12" />
          </S.BlackCross>
        </div>
        <S.LandingSideBarList>
          <li>
            {faq && (
              <a
                aria-label={faq.title}
                onClick={onClickFaq}
                href={faq.redirectUrl}
              >
                {faq.title}
              </a>
            )}
            <Image
              {...sidebarArr}
              style={{ margin: "0 0 0 auto" }}
              width="16"
              height="25"
            />
          </li>
          <li>
            {aboutUs && (
              <a aria-label={aboutUs.title} href={aboutUs.redirectUrl}>
                {aboutUs.title}
              </a>
            )}
            <Image
              {...sidebarArr}
              style={{ margin: "0 0 0 auto" }}
              width="16"
              height="25"
            />
          </li>
        </S.LandingSideBarList>
      </S.LandingSideBar>
      {show && <S.BackDrop onClick={handleClose}></S.BackDrop>}
    </React.Fragment>
  );
};

export default LandingSideBar;
