import styled from "styled-components";

export const LandingSideBar = styled.div<any>`
  background: #fff;
  position: fixed;
  top: 0;
  right: -240px;
  width: 240px;
  height: 100vh;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 4;

  ${(props) =>
    props.show &&
    `
    right:0;
    transition:0.5s ease-out;
  }
	`}
  ${(props) =>
    !props.show &&
    `
    right:-240px;
    transition:0.5s ease-out;
  }
	`}
`;

export const BlackCross = styled.div`
  margin: 0 16px 0 30px;
`;

export const LandingSideBarList = styled.ul`
  margin-bottom: 24px;
  width: 100%;
  // & li:first-child a {
  //   color: #979797;
  //   font-weight: 700;
  //   font-size: 14px;
  //   line-height: 17px;
  //   margin-bottom: 8px;
  // }
  & li {
    border-bottom: 1px solid #e8e8e8;
    padding: 12px 16px;
    position: relative;
    display: flex;
  }
  // & li:first-child {
  //   flex-direction: column;
  // }

  & li a {
    font-family: "Open Sans Light", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    width: 100%;
  }
`;

export const LandingProdList = styled.div`
  background: linear-gradient(91.62deg, #e4f7ff 0.03%, #d2e2ff 100.03%);
  border-radius: 6px;
  padding: 10px 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  position: relative;

  &:after {
    position: absolute;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 16px;
    top: 21px;
  }
`;

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 100%;
  height: 100vh;
`;
