import { useEffect, useState } from "react";
import * as S from "./LandingHeaderMobile.style";
import LandingSideBar from "../LandingSideBar/LandingSideBar";
import useHeaderMobileAnimation from "../../hooks/useHeaderMobileAnimation";
import { Image } from "../Image";
import LogoLanding from "../TMLogoLanding";

const TmLandingHeaderMobile = ({
  tmLogo,
  faq,
  aboutUs,
  hambugerBlack,
  hambugerWhite,
  sidebarArr,
  blackCross,
  faqCTAEvent,
  className,
}: any) => {
  const [show, setShow] = useState(false);
  const [isLight, setIsLight] = useState(className !== "about-us-mobile");
  useHeaderMobileAnimation(setIsLight);

  const handleClick = () => {
    setShow(!show);
  };
  return (
    <S.LandingHeader id="headerMobile_ref" isLight={isLight}>
      <S.LandingHeaderWrap>
        <S.tmLogo
          href={tmLogo?.redirectUrl}
          aria-label="tripmoney"
          id="tmlogo_mobile"
          className="lightRef"
        >
          <LogoLanding isLight={isLight} />
        </S.tmLogo>
        {isLight && (
          <S.HamBurger
            className="whiteHamRef"
            id="hamWhiteMobile_ref"
            onClick={handleClick}
          >
            <Image {...hambugerWhite} width="18" height="12" />
          </S.HamBurger>
        )}
        {!isLight && (
          <S.HamBurger id="hamBlackMobile_ref" onClick={handleClick}>
            <Image {...hambugerBlack} width="18" height="12" />
          </S.HamBurger>
        )}
        <LandingSideBar
          show={show}
          handleClose={handleClick}
          sidebarArr={sidebarArr}
          blackCross={blackCross}
          faq={faq}
          aboutUs={aboutUs}
          faqCTAEvent={faqCTAEvent}
        />
      </S.LandingHeaderWrap>
    </S.LandingHeader>
  );
};

export default TmLandingHeaderMobile;
