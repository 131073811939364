import styled from "styled-components";
import { SwitchTabStyle } from "@forex/Forex/TabsComp/TabsComp.style";
import { SpanTagStyle } from "@tm/ui/ui-container/Forex/TextStylesForex/TextStyles.style";

export const LandingHeader = styled.div<any>`
  height: 60px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
  background: ${(p) => (p.isLight ? "#000" : "rgb(245, 245, 245)")};
  color: rgba(255, 255, 255, 0.6);
  z-index: 40;

  & .displayNone {
    display: none;
  }
  ${(props) =>
    props.forexHeader &&
    `
  background:#fff;
  color: rgba(0, 0, 0, 0.6);
  top:-60px;
  `}
`;

export const LandingHeaderWrap = styled.div`
  width: 100%;
  padding: 18px 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const MobileHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 590px;
    margin: 0 auto;
    text-align: center;
  }
`;

export const tmLogo = styled.a<any>`
  width: 85px;
  height: 24px;
  flex-shrink: 0;
`;

export const LandingHeaderList = styled.ul`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 32px;
  cursor: pointer;

  & li {
    position: relative;
  }
  & li a {
    color: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  & li:first-child {
    margin-right: 16px;
  }

  & li:first-child:after {
    content: "";
    position: absolute;
    border: solid #008cff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 3px;
    right: -22px;
  }
`;

export const LandingHeaderBtn = styled.button`
  width: 90px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-weight: 900;
  color: #008cff;
  font-size: 16px;
  line-height: 20px;
  margin-left: 45px;
  border-radius: 96px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(93.66deg, #249083 0%, #249054 100%), #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;
  cursor: pointer;
`;

export const HamBurger = styled.div`
  margin-left: auto;
  &.whiteHamRef {
    position: absolute;
    right: 16px;
  }
`;

export const MobileHeader: any = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.2));
  height: 50px;
  overflow: hidden;
`;

export const MobileHeaderStyle: any = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background: #ffffff;
  position: ${(p: any) => (p.isIosGI ? "static" : "fixed")};
  margin-bottom: ${(p: any) => (p.isIosGI ? "-55px" : "0px")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.2));
  min-height: 70px;
  overflow: hidden;

  ${(p: any) =>
    p.noProcess &&
    `
    min-height:unset;
    `}
  ${(p: any) =>
    p.blackVersion &&
    `
    background:#000;
    `}

   
    ${(p: any) =>
    p.switchTabStyle &&
    `
     ${SwitchTabStyle}{
      margin: 0px -20px -16px;
    }
    `}
`;

export const ArrowWrap = styled(SpanTagStyle)`
  @media (min-width: 768px) {
    display: none;
  }
`;
